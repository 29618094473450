$primary-color: #326aff;
$lighter-primary-color: #5482ff;
$secondary-color: rgb(220, 218, 218);
$background-color: white;
$opposite-background-color: black;
$padding-size: 20px;

a {
  all: unset;
}


html,
body,
.app {
  height: 100%;
  margin: 0;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
}

.generalHeader {
  width: 100%;
  height: 50px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 $padding-size;
  box-sizing: border-box;
  background-color: $background-color;
  color: $opposite-background-color;
  padding: 10px 20px;
}

.logo {
  display: flex;
  align-items: center;
}

.generalHeader img {
  display: block;
  height: 20px;
  padding-right: 10px;
}

.fa-arrow-left-long {
  font-size: 40px;
}

.companyName {
  font-size: 18px;
  margin: 0;
  font-weight: bold;
  cursor: pointer;
}

.formStyles {
  margin: auto;
  width: 300px;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
}

.formStyles p {
  margin-bottom: 5px;
}

.formStyles input {
  margin-bottom: 10px;
  border-radius: 6px;
  border: solid 1px rgb(161, 161, 161);
  height: 1.5rem;
  padding: 5px 7px;
  font-size: 16px;
}

.formStyles input::placeholder {
  color: $secondary-color;
}

.formStyles input:focus-visible {
  border: solid 1px $primary-color;
}

.formHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.formHeader>div:first-of-type {
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.createClientHeader {
  width: 250px !important;
}

.formHeader .fa-trash {
  color: red;
  font-size: 30px;
}

.sliderLabels {
  margin-bottom: 8px;
  // position: relative;
}

.errorMessage {
  color: $background-color;
  background-color: red;
  padding: 10px;
  margin-top: 0 !important;
  border-radius: 6px;
}


.userHeader {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: relative;
  width: max-content;
  align-items: center;
}

.userHeader button {
  padding: 5px 10px;
  background-color: $primary-color;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 10px;
}


form {
  display: flex;
  flex-direction: column;
}


canvas {
  margin: auto;
  width: min(100vh, 100vw) !important;
  height: min(100vh, 100vw) !important;
  background-color: $background-color;
}


.ratings {
  display: flex;
  align-items: center;
}

.ratings p {
  border-right: solid 1px black;
}

.mainBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100%;
  background-color: $background-color;
  color: $opposite-background-color;
}

.loader {
  margin: auto;
  border: 16px solid #f3f3f3;
  border-top: 16px solid $primary-color;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

input[type="submit"] {

  padding: 8px 16px;
  font-size: 14px;
  height: 35px;
  border-radius: 6px;
  border: none;
  background-color: $primary-color;

  color: $background-color;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
}

input[type="submit"]:hover {
  background-color: $lighter-primary-color;
}

.clientListContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.clientListHeader,
.ratingListHeader {
  background-color: $primary-color;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 $padding-size;
  justify-content: space-between;
  color: $background-color;
}


.clientListHeader h1,
.ratingListHeader h1 {
  margin: 0;
  color: $background-color;
  font-weight: normal !important;
  font-size: 20px;
}

.addClientButton,
.ratingListHeader button {
  height: 30px;
  background-color: $background-color;
  border-radius: 6px;
  border: none;
  color: $primary-color;
  width: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
}

.addClientButton {
  white-space: nowrap;
}

.ratingListHeader button {
  height: 25px;
  font-size: 23px;
  display: flex;
  justify-content: space-around;
  padding: 16px 0;
  margin-left: 10px;
}

.ratingListHeader>div {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  color: $background-color;
}

.ratingIcons {
  font-size: 30px;
}

.clientsTable {
  border-collapse: collapse;
  margin-top: 10px;
  padding: 0 $padding-size;
}

.clientsTable,
.clientsTable table {
  width: calc(100% - 17px);
}

.clientsTable th,
.clientsTable td {
  text-align: left;
}

.clientsTable th {
  background-color: #f2f2f2;
}

.clientData {
  width: 80px;
  display: flex;
  justify-content: space-between;
}

.clientIcons>a {
  margin-left: 5px;
  color: #333;
  text-decoration: none;
}

.clientData a:hover {
  color: $lighter-primary-color;
}

.clientData p {
  margin: 0;
}

.ratingData {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
}

.ratingData a {
  padding: 5px 10px;
  margin-right: 5px;
  background-color: $primary-color;
  color: $background-color;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
}

.ratingData a:hover {
  background-color: $lighter-primary-color;
  /* Hover color */
}

.clientData .fa-pen,
.actions .fa-pen {
  color: $primary-color;
}

.clientData .fa-trash,
.actions .fa-trash {
  color: red;
}


.logoutButton {
  width: fit-content;

}

.ratingTable {
  padding: 0 $padding-size;
  margin-bottom: 50px;
}

.ratingData {
  width: 100%;
}

.ratingTable td {
  text-align: center;
}


.catData {
  display: none !important;
}

.ratingTable table {
  border-collapse: collapse;
  width: 300px;
}

.ratingTable th:not(.actions),
.ratingTable td:not(.actions) {
  box-sizing: border-box;
  border-bottom: 1px solid $secondary-color;
  border-collapse: collapse;
}


.actions {
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  align-items: center;
}

.noRatings {
  width: 100%;
}

.noRatings>p {
  margin: auto;
  width: max-content;
}

.chart-container {
  position: relative;
  width: 100%;
}

.ratingTable {
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.rs-slider {
  left: 2px;
}

.sliderNums {
  width: calc(100% + 13px);
  display: flex;
  justify-content: space-between;
}

.ratingsFilter {
  margin: auto;
}

.ratingsFilterContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 330px;

}

.ratingsFilterContainer select,
.ratingsFilterContainer input {
  padding: 8px;
  border: 1px solid $secondary-color;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 15px;
}

.ratingsFilterContainer button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: $primary-color;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 15px;
}

.ratingsFilterContainer button:hover {
  background-color: $lighter-primary-color;
}


@media screen and (min-width: 767px) {
  .mainBody {
    width: 100%;
  }

  .generalHeader {
    margin: 10px 0;
  }

  .generalHeader img {
    height: 40px;
  }

  .clientListHeader,
  .ratingListHeader {
    height: 75px;
  }

  .clientListHeader h1,
  .ratingListHeader h1,
  .companyName {
    font-size: 35px;
  }

  .addClientButton,
  .ratingListHeader button {
    width: 140px;
  }

  .addClientButton,
  .ratingListHeader button,
  .userHeader button {
    height: 40px;
  }

  .clientData {
    width: 120px;

  }

  .ratingData,
  .userHeader,
  .userHeader button,
  .addClientButton,
  .ratingListHeader button,
  .clientData {
    font-size: 20px;
  }

  .ratingData a {
    height: 25px;
  }

  .clientData p {
    font-size: larger;
  }

  .clientsTable,
  .clientsTable table {
    width: calc(100% - 16px);
  }

  .ratingListHeader button {
    width: 81px;
  }
}

@media screen and (min-width: 1200px) {
  $padding-size: 50px;


  .generalHeader,
  .clientListHeader,
  .clientsTable,
  .ratingListHeader,
  .ratingTable {
    padding: 0 $padding-size;
  }

  .clientsTable,
  .clientsTable table {
    width: calc(100% - 45px);
  }

  .clientData {
    width: 150px;
  }

  .clientIcons {
    font-size: 30px;
  }

  .clientIcons a {
    margin-left: 10px;
  }

  .clientData p {
    font-size: 30px;
  }


  .ratingData a {
    height: 48px;
    font-size: 30px;
  }

  .catData {
    display: table-cell !important;
  }

  .clientListHeader h1,
  .ratingListHeader h1 {
    font-size: 40px;
  }

  .ratingIcons {
    font-size: 40px;
  }



  .addClientButton,
  .ratingListHeader button {
    height: 48px;
    font-size: 30px;
    width: 110px;
  }

  .addClientButton {
    width: 200px;
    height: 58px;
  }

  .userHeader {
    font-size: 30px;
  }

  .userHeader button {
    font-size: 30px;
    width: 112px;
    height: 46px;
  }

  input[type=submit] {
    font-size: 30px;
    height: 45px;
  }

  .actions {
    height: 79px;
  }

  .ratingTable table {
    width: calc(100% - 20px);
  }
}